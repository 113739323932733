<template>
  <v-app class="main">
    <navigation v-if="showNav" v-resize="onResize"> </navigation>
    <v-main>
      <router-view v-if="showContent"> </router-view>
      <loading v-else> </loading>
    </v-main>
    <dashFooter></dashFooter>
    <snackBar></snackBar>
    <announcement></announcement>
    <incoming-call-dialog></incoming-call-dialog>
    <outgoing-call-dialog></outgoing-call-dialog>
    <call-status-dialog></call-status-dialog>
  </v-app>
</template>

<script>
import snackBar from "@/components/notifications/snackBar.vue";
import announcement from "@/components/notifications/announcement.vue";
import navigation from "@/components/navigation/navigation.vue";
import loading from "@/components/loading.vue";
import dashFooter from "@/components/footer.vue";
import { mapGetters } from "vuex";
import IncomingCallDialog from "./components/telepresence/incomingCallDialog.vue";
import OutgoingCallDialog from "./components/telepresence/outgoingCallDialog.vue";
import CallStatusDialog from "./components/telepresence/callStatusDialog.vue";
import telepresence from "./components/telepresence.vue";

export default {
  name: "App",
  components: {
    snackBar,
    navigation,
    loading,
    dashFooter,
    announcement,
    IncomingCallDialog,
    OutgoingCallDialog,
    CallStatusDialog,
    telepresence,
  },
  data() {
    return {
      showContent: false,
    };
  },
  computed: {
    ...mapGetters([
      "userProfile",
      "appData",
      "allRegisteredUsers",
      "getUserCallStatus",
    ]),
    showNav() {
      return Object.keys(this.userProfile).length > 1;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAppData").then(() => {
      if (this.appData.initiated) {
        this.$vuetify.theme.themes.light = this.appData.themes.light;
      }
      this.showContent = true;
    });
  },
  watch: {
    userProfile(newVal) {
      this.$store.dispatch("getUserGroups").then(
        () => {
          this.loading = false;
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
  },
  methods: {
    onResize() {
      this.$store.commit("setWindowDimensions", {
        height: window.innerHeight,
        width: window.innerWidth,
      });
    },
  },
  mounted() {
    if(Notification.permission !== "granted"){
      Notification.requestPermission()
    }
    window["console"]["log"] = function () {};
    console.warn = function () {};
  },
};
</script>

<style>
.main {
  background-color: #f5f5f5 !important;
}
body::-webkit-scrollbar {
  display: none;
}
.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}
.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}
.v-data-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}
.v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}
.miniplayer {
  z-index: 99999999 !important;
  padding-bottom: 30px;
  position: absolute;
  bottom: 1cm;
  right: 1cm;
}
/* .v-virtual-scroll::-webkit-scrollbar { */
/* -webkit-appearance: none; */
/* color: #6b1530; */
/* width: 10px; */
/* } */
/* .v-virtual-scroll::-webkit-scrollbar-thumb { */
/* border-radius: 2px; */
/* background-color: rgba(0, 0, 0, .5); */
/* -webkit-box-shadow: 0 0 1px #6b1530; */
/* } */
</style>
