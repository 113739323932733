import * as fb from "../../firebase";
import router from "../../router";
import store from "../index";
import axios from 'axios';

export default {
  state: {
    invitedUserProfile: {},
    userProfile: {},
    userId: null,
    token: null,
    activeSession: 0,
    superAdmin: false
  },
  getters: {
    userProfile: state => state.userProfile,
    invitedUserProfile: state => state.invitedUserProfile,
    userId: state => state.userId,
    getActiveSession: state => state.activeSession,
    isSuperAdmin: state => state.superAdmin
  },
  mutations: {
    setUserProfile(state, data) {
      state.userProfile = data;
    },
    setInvitedUserProfile(state, data) {
      state.invitedUserProfile = data;
    },
    setUserId(state, uid) {
      state.userId = uid;
    },
    setToken(state, token) {
      state.token = token
    },
    setActiveSession(state, count) {
      state.activeSession = count
    },
    setSuperAdmin(state, status) {
      state.superAdmin = status
    }
  },
  actions: {
    login({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {
          console.log(`user logged In: ${cred.user.uid}`);
          //fetch user profile
          dispatch('fetchUserProfile', cred.user);
          resolve('Successfully Logged In');
        })
          .catch(error => {
            reject(error.message)
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    logout({ commit, dispatch }) {
      console.log('Logout called? ')
      return new Promise((resolve, reject) => {
        //set user Unavailable
        dispatch('setUserUnavailable');
        fb.auth.signOut()
          .then(() => {
            commit('setUserProfile', {});
            commit('setUserId', null);
            router.push('/login');
            resolve('Successfully Logged Out')
            location.reload();
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })

      })
    },
    createAdminAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              dispatch('initiateAppData')
                .then(() => {
                  resolve('Account Created Successfully.');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    fetchUserProfile({ commit, dispatch }, user) {
      //fetch user profile data
      fb.userCollection.doc(user.uid)
        .onSnapshot(userData => {
          console.log('User Data', userData.data())
          if (userData.data() === undefined) {
            console.log('It is coming in?')
            dispatch('logout')
          } else {
            //set userProfile in state
            commit('setUserProfile', userData);
            //commit('setActiveSession', userData.data().activeSession.length)
            //set userId in state
            commit('setUserId', user.uid);

            if (userData.data().userRole === 'Super Admin') {
              commit('setSuperAdmin', true)
            }
            //change route to dashboard
            if (router.currentRoute.path === '/login' || router.currentRoute.path === '/admin') {
              router.push('/')
            }
          }
        });
    },
    inviteNewUser({ state }, user) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.add({
          name: user.name,
          email: user.email,
          userRole: user.userRole,
          fdn: user.fdn,
          createdOn: new Date(),
        }).then(() => {
          resolve('Invitation sent successfully.');
        })
          .catch(error => {
            reject(error.message);
          })

      })
    },
    getInvitedUserInformation({ commit }, uId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(uId).get()
          .then(userData => {
            commit('setInvitedUserProfile', userData.data());
            resolve('Fetched User Information');
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    createAccount({ commit }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              fb.invitedUsersCollection.doc(form.invitationId).delete()
                .then(() => {
                  fb.auth.signOut();
                  commit('setUserProfile', {});
                  commit('setUserId', null);
                  resolve('Account Created Successfully. Login to Continue');
                  router.push('/login');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    setUserAvailable({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Available',
      })
    },
    setUserOffCall({ state, getters }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Available',
        onCall: false
      })
    },
    setUserBusy({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
      })
    },
    setUserOnCall({ state, getters }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
        onCall: true
      })
    },
    setUserUnavailable({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Unavailable',
      })
    },

    sessionChanges() {

    },

    manageUserPresence({ getters, dispatch }, user) {
      //let session = Math.round(Math.random() * 1000)
      var userStatusDatabaseRef = fb.firebase.database().ref('/status/' + user.uid);
      var myStorage = window.sessionStorage;
      //myStorage.setItem('sessionID', session.toString());

      // let sessions = getters.userProfile.data().activeSession
      // sessions.push(session.toString())


      // var isOfflineForDatabase = {
      //   sessionID: session.toString(),
      //   state: 'Unavailable',
      //   last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      // };
      //session.toString()
      if (getters.userProfile === undefined) {
        dispatch('logout')
      } else {
        if (getters.userProfile.data().onlineStatus === 'Busy') {
          var isOnlineForDatabase = {
            state: 'Busy',
            last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
          };
        } else {
          isOnlineForDatabase = {
            state: 'Available',
            last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
          };
        }
      }



      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) return;
        userStatusDatabaseRef.push(isOnlineForDatabase).then(response => {
          myStorage.setItem('sessionID', response.key.toString());
          var userStatusDatabaseRefDel = fb.firebase.database().ref('/status/' + user.uid + '/' + response.key);
          userStatusDatabaseRefDel.onDisconnect().remove()
        })
      });

    }
  } // Actions
} // Main end
