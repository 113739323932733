export default {
    state: {
        stripe: require('stripe')('sk_test_51KYufDEV5PWIaZOvtUTl5a0ALj9vVMo2uGQhloOrlXqKvwtFGgQSiUuUaZNIqr82jmDSXti3HzRiYbFDGrsGMGDd00PMG5gK4X')
    },
    getters: {
        getStripe: state => state.stripe
    },
    mutations: {

    },
    actions: {
        async createStripeCustomer({ state }, kioskID) {
            console.log('Create Stripe Kiosk is being Called: ', kioskID)
            // create customer on stripe here
            const stripe = require('stripe')('sk_test_51KYufDEV5PWIaZOvtUTl5a0ALj9vVMo2uGQhloOrlXqKvwtFGgQSiUuUaZNIqr82jmDSXti3HzRiYbFDGrsGMGDd00PMG5gK4X')
            const product = await stripe.products.create({name: 'Telepresence'});

            // const customer = await stripe.customers.create({
            //     description: kioskID
            // })
            //console.log('Added Customer: ', customer)
            // try {
            //     state.stripe.customers.create({
            //         description: kioskID
            //     }).then(response => {
            //         console.log('Response: ', response)
            //     })
            // } catch (e) {
            //     console.log(e.message)
            // }

        }
    }

}