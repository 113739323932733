<template>
  <div class="dashboard">
    <v-fade-transition leave-absolute>
      <router-view></router-view>
    </v-fade-transition>
  </div>
</template>

<script>
import * as fb from "../firebase";
export default {
  name: "Dashboard",
  computed: {},
  mounted() {},
};
</script>
