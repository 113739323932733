<template>
  <v-app-bar app flat dark color="navBarColor">
    <!-- <v-img
      @click="showThis"
      src="@/assets/logo.png"
      contain
      justify="center"
      max-height="50"
      max-width="200"
      class="my-auto"
    >
    </v-img> -->

    <v-spacer></v-spacer>
    <v-toolbar-items>
      <notifications></notifications>
      <profileMenu></profileMenu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import Notifications from "./notifications.vue";
import profileMenu from "./profileMenu";

export default {
  name: "navBar",
  components: {
    profileMenu,
    Notifications,
  },
  methods: {
    showThis() {
      this.$store.dispatch("showAnnouncement", {
        text:
          "Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum " +
          "Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum ",
      });
      console.log("Clicked");
    },
  },
};
</script>