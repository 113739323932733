import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { auth } from './firebase/index';
import { mapGetters } from 'vuex';
import axios from 'axios';
Vue.config.productionTip = false;
let app;

auth.onAuthStateChanged(user => {
  if (!app) {

    app = new Vue({
      router,
      store,
      vuetify,
      render: function (h) { return h(App) },
      // add next tick to pass telepresence
      data() {
        return {
          telepresence: undefined,
          count: 1
        }
      },
      computed: {
        ...mapGetters(["getUserCallStatus", "userProfile"])
      },
      watch: {
        userProfile(newVal) {
          if (newVal.data().onlineStatus === 'Available' && this.count === 1) {
            store.dispatch("pullCallHistory");
            store.dispatch("handleIncomingRequest")
            store.dispatch("handleGroupIncomingRequest")
            //this.stripeAccount()
          //   const stripe = require('stripe')('sk_test_51KYufDEV5PWIaZOvtUTl5a0ALj9vVMo2uGQhloOrlXqKvwtFGgQSiUuUaZNIqr82jmDSXti3HzRiYbFDGrsGMGDd00PMG5gK4X')
          //   const config = {
          //     method: 'post',
          //     url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/createStripeCustomer/a763cd056f1b2405788443b7197e0708',
          //     params: stripe
          // }
          // axios(config)
          //     .then(result => {
          //         console.log('Result from axios call', result)
          //     })
          //     .catch(error => {
          //         console.log('Error : ', error.message)

          //     })

            this.count = 2;
          }
        }
      },
      methods: {
        // stripeAccount() {
        //   console.log('Are coming here')
        //   var stripe = Stripe('pk_test_51KYufDEV5PWIaZOvzGge2ko7huetBDE8ZDeiS96IHjG7nLsJzDYrUfXHGTMJaRYMGzBa6JUAZPPVZ1RV19McIj1y00EM0PAtXB');
        //   //const stripe = require('stripe')('sk_test_51KYufDEV5PWIaZOvtUTl5a0ALj9vVMo2uGQhloOrlXqKvwtFGgQSiUuUaZNIqr82jmDSXti3HzRiYbFDGrsGMGDd00PMG5gK4X')
        //   stripe.products.create({ name: 'Telepresence' }).then(product => {
        //     console.log('Added Product: ', product)
        //   });

       // }
        // findTelepresenceComponent: function (parent) {
        //   let found = parent.$children.find(child => { return child.$options.name === "telepresence"; });
        //   for (let i = 0; !found && i < parent.$children.length; i++) {
        //     found = this.findTelepresenceComponent(parent.$children[i]);
        //   }
        //   console.log('Found: ', found)
        //   return found;
        // },
      }



    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user).then(() => {
      setTimeout(() => {
        store.dispatch('manageUserPresence', user);
      }, 1500)
    })
  }
})




