import * as fb from '../../firebase/index.js'
import router from "../../router";
import crypto from "crypto"
import axios from 'axios';
import { map } from 'lodash';
export default {
    state: {
        outgoingCallDialog: {},
        callInitiateListener: undefined,
        meetingCode: null,
        outgoingCall: [],
        callEndTimer: 0,
        timeoutMap: new Map()
    },
    getters: {
        getOutgoingCallDialog: state => state.outgoingCallDialog,
        getCallInitiateListener: state => state.callInitiateListener,
        getMeetingCode: state => state.meetingCode,
        getOutgoingCall: state => state.outgoingCall,
        getCallEndTimer: state => state.callEndTimer,
    },
    mutations: {
        setOutgoingCallDialog(state, obj) {
            state.outgoingCallDialog = obj
        },
        closeOutgoingCallDialog(state) {
            state.outgoingCallDialog = { show: false }
        },
        setMeetingCode(state, id) {
            state.meetingCode = id
        },
        setOutgoingCall(state, meeting) {
            if (meeting === null) {
                state.outgoingCall = []
            } else {
                state.outgoingCall.push(meeting)
            }
        }
    },
    actions: {
        cancelCall({ dispatch, commit, getters, state }, callDetails) {
            state.timeoutMap.delete(callDetails.callId)
            //clearTimeout(state.callEndTimer)
            if (router.currentRoute.path !== '/liveCall') {
                dispatch("setUserOffCall");
            }
            getters.getCallInitiateListener()
            fb.telepresenceCollection.doc(callDetails.callId).update({
                callStatus: "Cancelled",
                requestedUser: null,
                source: false
            }).then(() => {
                commit('closeOutgoingCallDialog')
            });
        },

        callNotAnswered({ dispatch, getters, commit, state }, id) {
            state.timeoutMap.delete(id)
            //clearTimeout(state.callEndTimer)
            if (router.currentRoute.path !== '/liveCall') {
                dispatch("setUserOffCall");
                getters.getCallInitiateListener();
                fb.telepresenceCollection
                    .doc(id)
                    .update({
                        callStatus: "notAnswered",
                        requestedUser: null,
                        source: false
                    }).then(() => {
                        commit('closeOutgoingCallDialog')
                    });
            }
            //commit('closeOutgoingCallDialog')

        },

        requestCall({ dispatch, commit, getters }, callInfo) {
            // Step 1: Check User/Users Availability
            // If requested User not Available do not initiate the Call
            // For group call check -> Atleast 1 participant should be available for call to initiate
            callInfo["addToCall"] = false
            dispatch('checkUserAvailabilty', callInfo).then(response => {
                if (response.startCall) {

                    if (response.type === 'Single') {
                        if (!getters.userProfile.data().onCall) {
                            if (getters.getMeetingCode === null) {
                                commit('setMeetingCode', crypto.randomBytes(50).toString("hex").slice(0, 20))
                            }
                            dispatch('startCall', {
                                call: callInfo,
                                meetingID: getters.getMeetingCode
                            })
                        } else {
                            dispatch('addToExistingCall', callInfo)
                        }
                    } else { // Group Call
                        callInfo.userId = response.availUser
                        if (!getters.userProfile.data().onCall) {
                            if (getters.getMeetingCode === null) {
                                commit('setMeetingCode', crypto.randomBytes(50).toString("hex").slice(0, 20))
                            }
                            dispatch('startCall', {
                                call: callInfo,
                                meetingID: getters.getMeetingCode
                            })
                        } else {
                            dispatch('addToExistingCall', callInfo)
                        }
                    }
                } else {
                    commit("setCallStatusDialog", {
                        show: true,
                        message: response.message,
                    })
                }
            })
        },

        checkUserAvailabilty({ getters }, callInfo) {
            return new Promise((resolve, reject) => {
                // For one to one Call



                if (callInfo.callType === 'Single') {
                    let userInfo = getters.allRegisteredUsers.find(user => user.id === callInfo.userId[0])
                    if (userInfo.data().onlineStatus === 'Available' && !userInfo.data().onCall) {
                        resolve({ startCall: true, type: 'Single' })
                    } else {
                        let text;
                        if (userInfo.data().onlineStatus === 'Available') {
                            text = 'on another call'
                        } else {
                            text = userInfo.data().onlineStatus
                        }
                        resolve({ startCall: false, message: userInfo.data().name + ' is Currently ' + text })
                    }
                }

                if (callInfo.callType === 'Pull') {
                    // Group Call
                    let availUsers = callInfo.userId.filter(user => getters.allRegisteredUsers.find(usr => usr.id === user).data().onlineStatus === 'Available'
                        && getters.allRegisteredUsers.find(usr => usr.id === user).data().onCall === false)

                    console.log('Called group Info: ', callInfo)
                    if (availUsers.length > 0) {
                        resolve({ startCall: true, availUser: availUsers, type: 'Pull' })
                    } else {
                        resolve({ startCall: false, message: 'No User from group ' + callInfo.userInfo.name + ' is Available' })
                    }
                }

                if (callInfo.callType === 'Group') {
                    let availUsers = callInfo.userId.filter(user => getters.allRegisteredUsers.find(usr => usr.id === user).data().onlineStatus === 'Available'
                        && getters.allRegisteredUsers.find(usr => usr.id === user).data().onCall === false)
                    console.log('Called group Info: ', availUsers)
                    if (availUsers.length > 0) {
                        resolve({ startCall: true, availUser: availUsers, type: 'Group' })
                    } else {
                        resolve({ startCall: false, message: 'No User from group ' + callInfo.userInfo.name + ' is Available' })
                    }
                }
            })
        },

        startCall({ getters, dispatch, state, commit }, callInfo) {

            // Call Object to stored on Firebase to start and request users for Call
            console.log('At Start Call: ', callInfo)
            let callObj = {
                callStatus: "Initiated",
                meetingCode: callInfo.meetingID,
                startTime: new Date(),
                endTime: null,
                conferenceAttendees: [],
                callerInfo: getters.userProfile.data(),
                callInitiator: { name: getters.userProfile.data().name, id: getters.userProfile.id, session: window.sessionStorage.getItem('sessionID').toString() },
                callType: callInfo.call.callType,
                requestedUser: callInfo.call.userId,
                calledUser: callInfo.call.userInfo.name,
                availableUsers: callInfo.call.userId,
                source: true
            };

            // Adding the Outgoing Call object to firestore telepresence collection

            fb.telepresenceCollection.add(callObj).then(response => {
                commit('setOutgoingCall', response.id)
                dispatch('setUserOnCall')
                state.timeoutMap.set(response.id, setTimeout(() => {
                    dispatch('callNotAnswered', response.id)
                    state.timeoutMap.delete(response.id)
                    commit('closeOutgoingCallDialog')
                }, 20000))

                if (callObj.callType === 'Group' || callObj.callType === 'Pull') {

                    // Group call Declined state is handled using CLoud Functions
                    // To check cloud function please check Cloud Functions folder

                    const config = {
                        method: 'post',
                        url: 'https://us-central1-fir-dashboard-d788d.cloudfunctions.net/apiV2/getCallInfo/a763cd056f1b2405788443b7197e0708',
                        params: { call: response.id, action: 'get' }
                    }
                    axios(config)
                        .then(result => {
                            console.log('Result from axios call', result)
                        })
                        .catch(error => {
                            console.log('Error : ', error.message)

                        })
                }

                // A Snapshot listeners is started to get realtime updates of the Call Started.
                // Detach the listener once call is in Not Answered, Ended, Cancelled and Declined

                state.callInitiateListener = fb.telepresenceCollection.doc(response.id)
                    .onSnapshot(callDetails => {
                        //clearTimeout(callEndTimer)
                        if (callDetails.data().callStatus !== 'Initiated') {
                            //clearTimeout(state.callEndTimer)
                            state.timeoutMap.delete(callDetails.id)
                        }
                        dispatch('callHandling', callDetails)
                    })
            })

        },
        addToExistingCall({ dispatch, getters }, callInfo) {
            console.log('Add to existing call', callInfo)
            dispatch('endExistingCalls', getters.getOutgoingCall).then(() => {
                callInfo.addToCall = true
                dispatch("startCall", {
                    call: callInfo,
                    meetingID: getters.getMeetingCode
                })
            })
        },

        callHandling({ commit, getters, dispatch }, callDetails) {
            let addTo;
            switch (callDetails.data().callStatus) {
                case 'Initiated':
                    // Open an outgoing dialog
                    // Do not open if already in call -> just update the call

                    if (router.currentRoute.path !== '/liveCall') {
                        addTo = true
                    } else {
                        addTo = false
                    }
                    commit("setOutgoingCallDialog", {
                        show: true,
                        callerInfo: callDetails.data().calledUser,
                        callId: callDetails.id,
                        callText: 'Calling...',
                        inCall: true,
                        callIcon: 'mdi-phone-hangup',
                        addToExisting: addTo
                    })


                    break;
                case 'addingUser':
                    // open snack bar
                    break;
                case 'inProgress':
                    // End User accepted the call
                    // Things to do: -> 1) Close Outgoing Call Dialog
                    commit('closeOutgoingCallDialog')
                    commit("setMeetingUrl", {
                        meetingCode: callDetails.data().meetingCode,
                        meetingId: callDetails.id,
                        initiator: callDetails.data().callInitiator.name
                    });
                    router.push('/liveCall')
                    break;
                case 'Ended':
                    getters.getCallInitiateListener();
                    break;
                case 'Declined':
                    console.log('At declined: ', callDetails.data())
                    if (router.currentRoute.path !== '/liveCall') {
                        dispatch("setUserOffCall");
                    }
                    commit('closeOutgoingCallDialog')
                    if (callDetails.data().callType === 'Group' || callDetails.data().callType === 'Pull') {
                        commit("setCallStatusDialog", {
                            show: true,
                            message: 'All available users of group ' + callDetails.data().calledUser + ' declined the call',
                        })
                    } else {
                        commit("setCallStatusDialog", {
                            show: true,
                            message: callDetails.data().calledUser + ' declined the call',
                        })
                    }
                    break;
            }
        }

    }
}