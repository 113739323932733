var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"35%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-6 py-0",attrs:{"elevation":"0","color":"primary","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Conference Call ")],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"width":"100%","height":"100%"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Conference Call")]),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"6","align":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"width":"80%","color":"primary","outlined":""},on:{"click":function($event){return _vm.conferenceCall(
                { name: _vm.groupName },
                _vm.getGroupInfo,
                _vm.groupName,
                'Pull'
              )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-video")]),_vm._v(" Pool Call ")],1)],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"width":"80%","color":"primary"},on:{"click":function($event){return _vm.conferenceCall(
                { name: _vm.groupName },
                _vm.getGroupInfo,
                _vm.groupName,
                'Group'
              )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-video")]),_vm._v(" Group Call ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }