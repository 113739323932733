<template>
  <v-navigation-drawer color="white" permanent clipped-left app>
    <v-app-bar flat>
      <v-img
        src="@/assets/logo.png"
        contain
        justify="center"
        max-height="50"
        max-width="200"
        class="my-auto"
      >
      </v-img>
    </v-app-bar>
    <v-divider></v-divider>
    <v-card height="75%" flat tile class="overflow-y-auto pa-0">
      <v-list dense>
        <v-list-item @click="navigate('/')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Home </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-group prepend-icon="mdi-account-circle" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Manage </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="item in manage"
            :key="item.title"
            @click="navigate(item.link)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"> </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-text="item.icon"> </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-group prepend-icon="mdi-google-analytics" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Analytics </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="item in analytics"
            :key="item.title"
            @click="navigate(item.link)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"> </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-text="item.icon"> </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-group>
        <v-divider></v-divider>
      </v-list>
    </v-card>
    <template v-slot:append>
      <v-divider></v-divider>
      <org-group-list></org-group-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import orgGroupList from "../../orgGroupList.vue";
import router from "@/router";
export default {
  components: { orgGroupList },
  name: "adminDrawer",
  data() {
    return {
      selectedItem: 1,
      category: null,
      availableUsers: 0,
      manage: [
        {
          title: "Users",
          icon: "mdi-account-multiple-outline",
          link: "/userManagement",
        },
        {
          title: "Organization Groups",
          icon: "mdi-account-group-outline",
          link: "/groupManagement",
        },
        {
          title: "Roles",
          icon: "mdi-card-account-details-outline",
          link: "/roleManagement",
        },
        {
          title: "Kiosks",
          icon: "mdi-presentation-play",
          link: "/kioskManagement",
        },
      ],
      announcements: [],
      analytics: [
        {
          title: "Kiosk",
          icon: "mdi-presentation-play",
          link: "/kioskAnalytics",
        },
        {
          title: "User",
          icon: "mdi-account-multiple-outline",
          link: "/userAnalytics",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "userRoles",
      "userId",
      "userProfile",
      "userGroups",
    ]),
    filteredUsers() {
      let users = [];
      this.allRegisteredUsers.forEach((user) => {
        if (
          user.data().groups.includes(this.category) &&
          user.id !== this.userId
        ) {
          users.push(user);
        }
      });
      return users;
    },
    getAvailableUsers() {
      let users = [];
      this.filteredUsers.forEach((user) => {
        if (user.data().onlineStatus === "Available") {
          users.push(user.id);
        }
      });
      return users;
    },
  },
  methods: {
    getColor(status) {
      if (status === "Unavailable") return "error";
      if (status === "Busy") return "amber";
      if (status === "Available") return "success";
    },
    initiateCall(userInfo, userId, userRole) {
      this.$store.dispatch("initiateCall", {
        userInfo: userInfo,
        userId: userId,
        userRole: userRole,
      });
    },
    navigate(link) {
      if (router.currentRoute.path === "/liveCall") {
        window.open(link, "_blank");
      } else {
        router.push(link);
      }
    },
  },
};
</script>