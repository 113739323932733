<template>
  <v-card flat :height="listHeight">
    <v-card-title class="text-subtitle-1 font-weight-medium py-2 justify-center"
      >Organization Groups</v-card-title
    >
    <v-divider></v-divider>
    <v-autocomplete
      hide-details
      height="25"
      label="Select Group"
      class="px-3 py-2"
      :items="getGroups"
      v-model="category"
      outlined
      dense
    >
    </v-autocomplete>
    <v-text-field
      label="Search By Name"
      v-model="search"
      class="px-3 pb-0"
      clearable
      v-if="category !== null"
    ></v-text-field>
    <v-list class="py-0">
      <v-card flat tile :height="listHeight / 1.7" class="overflow-y-auto overflow-x-hidden">
        <v-list-item v-if="category !== null && category !== 'All Members'">
          <conferenceCallDialog :groupName="category"></conferenceCallDialog>
        </v-list-item>
        <v-list-item v-for="(user, i) in filteredUsers" :key="i">
          <v-row class="pr-3">
            <v-col cols="10" align-self="center" class="py-0">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small left :color="getColor(user.data().onlineStatus)"
                    >mdi-checkbox-blank-circle</v-icon
                  >
                  {{ user.data().name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="2">
              <v-list-item-action>
                <v-icon
                  color="primary"
                  @click="
                    initiateCall(
                      user.data(),
                      [user.id],
                      user.data().userRole,
                      'Single'
                    )
                  "
                  class="pl-1"
                >
                  mdi-video
                </v-icon>
              </v-list-item-action>
            </v-col>
          </v-row>
        </v-list-item>
      </v-card>
    </v-list>
  </v-card>
  <!-- <v-list class="py-0" :height="listHeight">
    <v-list-group :value="true" disabled>
      <template v-slot:activator>
        <v-list-item-title> Organization Groups </v-list-item-title>
        
      </template>
      <v-divider></v-divider>
      <v-autocomplete
        hide-details
        height="25"
        label="Select Group"
        class="px-3 py-2"
        :items="getGroups"
        v-model="category"
        outlined
        dense
      >
      </v-autocomplete>
      <v-text-field
        label="Search By Name"
        v-model="search"
        class="px-3 pb-0"
        clearable
      ></v-text-field>
      <v-list class="pt-0">
        <v-card flat tile :height="listHeight / 1.7" class="overflow-y-auto">
          <v-list-item v-if="category !== null && category !== 'All Members'">
            <conferenceCallDialog :groupName="category"></conferenceCallDialog>
          </v-list-item>
          <v-list-item v-for="(user, i) in filteredUsers" :key="i">
            <v-row class="pr-3">
              <v-col cols="10" align-self="center" class="py-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon
                      small
                      left
                      :color="getColor(user.data().onlineStatus)"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    {{ user.data().name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="2">
                <v-list-item-action>
                  <v-icon
                    color="primary"
                    @click="
                      initiateCall(
                        user.data(),
                        [user.id],
                        user.data().userRole,
                        'Single'
                      )
                    "
                    class="pl-1"
                  >
                    mdi-video
                  </v-icon>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
      </v-list>
    </v-list-group>
  </v-list> -->
</template>
<script>
import { mapGetters } from "vuex";
import conferenceCallDialog from "@/components/telepresence/conferenceCallDialog.vue";
import router from "@/router";
export default {
  components: { conferenceCallDialog },
  name: "orgGroupList",
  data() {
    return {
      category: null,
      userCatgory: ["Online", "Offline", "All"],
      search: "",
      listHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "userRoles",
      "userId",
      "userProfile",
      "userGroups",
      "getWindowDimensions",
    ]),
    getGroups() {
      let users = this.userGroups.map((grp) => grp.name);
      users.push("All Members");
      return users;
    },
    filteredUsers() {
      let users = [];
      if (this.category !== "All Members") {
        this.allRegisteredUsers.forEach((user) => {
          if ("groups" in user.data()) {
            if (
              user.data().groups.includes(this.category) &&
              user.id !== this.userId
            ) {
              users.push(user);
            }
          }
        });
        return users.filter((user) =>
          user.data().name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.allRegisteredUsers.filter(
          (user) =>
            user
              .data()
              .name.toLowerCase()
              .includes(this.search.toLowerCase()) && user.id !== this.userId
        );
      }
    },
    getGroupInfo() {
      let users;
      users = this.userGroups.find((grp) => grp.name === this.category).users;
      return users.filter((usr) => usr !== this.userId);
    },
  },
  methods: {
    getColor(status) {
      if (status === "Unavailable") return "error";
      if (status === "Busy") return "amber";
      if (status === "Available") return "success";
    },
    initiateCall(userInfo, userId, userRole, callType) {
      if (
        !this.userProfile.data().onCall ||
        router.currentRoute.path === "/liveCall"
      ) {
        this.$store.dispatch("requestCall", {
          userInfo: userInfo,
          userId: userId,
          userRole: userRole,
          callType: callType,
        });
      } else {
        this.$store.commit("setCallStatusDialog", {
          show: true,
          message: "Can not initiate a call",
        });
      }
    },
  },
  watch: {
    getWindowDimensions(newVal) {
      this.listHeight = newVal.height / 1.75;
    },
  },
  mounted() {
    this.listHeight = window.innerHeight / 1.75;
  },
};
</script>
<style>
.panelHeader {
  color: blue;
}
.panelContent {
  padding: 0px !important;
  max-width: 100%;
  max-height: 250px;
}
.listContent {
  overflow-y: auto;
  padding: 0px !important;
}
</style>
