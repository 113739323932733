import * as fb from '../../firebase/index.js'
import axios from 'axios'
export default {
    state: {
        userGroups: []
    },
    getters: {
        userGroups: state => state.userGroups,
    },
    mutations: {
        setUserGroups(state, userGroups) {
            state.userGroups = userGroups;
        }

    },
    actions: {
        getUserGroups({ commit, getters, dispatch }) {
            let groups = [], kiosks = [];
            return new Promise((resolve, reject) => {
                let queryRef;
                if (getters.userProfile.data().userRole === 'Admin' || getters.userProfile.data().userRole === 'Super Admin') {
                    // Admin has access to all User Groups 
                    // to avoid creating same purpose grp
                    queryRef = fb.userGroupsCollection
                } else {
                    // No Admins do not have access to creating User grp,
                    // Can only see info of the assigned organisation grp
                    queryRef = fb.userGroupsCollection.where("users", "array-contains", getters.userId)
                }

                queryRef.onSnapshot(querySnapshot => {
                    groups = []
                    if (querySnapshot.empty) {
                        resolve(null);
                    } else {
                        querySnapshot.forEach(group => {
                            //groups.push({ data: group.data(), id: group.id });
                            let data = group.data()
                            data["id"] = group.id
                            groups.push(data);
                            if (group.data().kiosk.length > 1) {
                                group.data().kiosk.forEach(item => {
                                    if (!kiosks.includes(item)) {
                                        kiosks.push(item)
                                    }

                                })
                            } else {
                                if (group.data().kiosk[0] !== undefined && !kiosks.includes(group.data().kiosk[0])) {
                                    kiosks.push(group.data().kiosk[0])
                                }
                            }
                        });
                    }

                    //Groups added to the store
                    commit('setUserGroups', groups);

                    // Fetched Kiosk assigned to User Groups
                    dispatch('getAllRegisteredKiosks', kiosks)

                    // Fetch Users associated to the group
                    dispatch('getAllRegisteredUsers')
                    resolve("Feteched all user groups")
                })
            })
        },
        addNewGroup({ dispatch }, group) {
            return new Promise((resolve, reject) => {
                const newGroup = {
                    name: group.name,
                    description: group.description,
                    dateCreated: new Date(),
                    lastUpdated: new Date(),
                    userCount: group.users.length,
                    users: group.users,
                    kiosk: group.assignedKiosks
                };

                group.users.forEach(user => {
                    fb.userCollection.doc(user).update({
                        groups: fb.firebase.firestore.FieldValue.arrayUnion(
                            group.name
                        )
                    })
                })

                fb.userGroupsCollection.add(newGroup)
                    .then(() => {
                        dispatch('getUserGroups');
                        resolve('New group added successfully')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        updateGroup({ dispatch, getters }, group) {
            return new Promise((resolve, reject) => {
                getters.userGroups.forEach(grp => {
                    if (grp.id === group.id) {
                        if (grp.name !== group.name) {
                            // update the user grp
                            group.users.forEach(user => {
                                fb.userCollection.doc(user).update({
                                    groups: fb.firebase.firestore.FieldValue.arrayRemove(
                                        grp.name
                                    )
                                })
                            })
                        }
                    }
                })

                fb.userGroupsCollection.doc(group.id).update({
                    name: group.name,
                    description: group.description,
                    lastUpdated: new Date(),
                    users: group.users,
                    kiosk: group.kiosk
                })
                    .then(() => {
                        //dispatch('getUserGroups');
                        group.users.forEach(user => {
                            fb.userCollection.doc(user).update({
                                groups: fb.firebase.firestore.FieldValue.arrayUnion(
                                    group.name
                                )
                            })
                        })
                        resolve('User group updated successfully.');
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })

        },
        deleteGroup({ dispatch }, groupId) {
            console.log('Group Id:', groupId)
            return new Promise((resolve, reject) => {
                fb.userGroupsCollection.doc(groupId.id).delete()
                    .then(() => {
                        const config = {
                            method: 'post',
                            url: 'https://us-central1-ottawa-dashboard.cloudfunctions.net/apiV2/deleteUserGroup/a763cd056f1b2405788443b7197e0708',
                            params: { users: groupId.users, groupName : groupId.name }
                        }
                        axios(config)
                            .then(result => {
                                console.log('Result from axios call', result)
                            })
                            .catch(error => {
                                console.log('Error : ', error.message)

                            })
                        dispatch('getUserGroups');
                        resolve('Group deleted successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },

        removeUserFromGroup({getters}, info){
            return new Promise((resolve, reject) => {
                fb.userCollection.doc(info.id).update({
                    groups: fb.firebase.firestore.FieldValue.arrayRemove(
                        info.groupName
                    )
                })
            })
        }

    }
}