<template>
  <v-dialog max-width="40%" v-model="getCallStatusDialog.show" persistent>
    <v-card rounded>
      <v-card-title class="justify-center">{{
        getCallStatusDialog.message
      }}</v-card-title>
      <!-- <v-divider></v-divider> -->
      <v-card-actions class="justify-center">
        <v-btn
          class="error--text"
          large
          text
          @click="$store.commit('closeCallStatusDialog')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "callStatusDialog",

  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["getCallStatusDialog"]),
  },
  methods: {
    closeDialog() {},
  },
};
</script>

<style>
</style>