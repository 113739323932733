import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Login.vue'
import notFound from '@/views/notFound.vue'
import { auth } from "@/firebase";
import store from "@/store"
Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Not Found',
    component: notFound,
    meta: {
      title: '404'
    }
  },
  {
    path: '/',
    // name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta: {
          title: 'Home'
        }
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        component: () => import('@/views/admin/userManagement.vue'),
        meta: {
          title: 'User Management'
        }
      },
      {
        path: '/groupManagement',
        name: 'groupManagement',
        component: () => import('@/views/admin/groupManagement.vue'),
        meta: {
          title: 'Group Management'
        }
      },
      {
        path: '/roleManagement',
        name: 'roleManagement',
        component: () => import('@/views/admin/roleManagement.vue'),
        meta: {
          title: 'Role Management'
        }
      },
      {
        path: '/kioskManagement',
        name: 'kioskManagement',
        component: () => import('@/views/admin/kioskManagement.vue'),
        meta: {
          title: 'Kiosk Management'
        }
      },
      {
        path: '/kioskAnalytics',
        name: 'kioskAnalytics',
        component: () => import('@/views/admin/kioskAnalytics.vue'),
        meta: {
          title: 'Kiosk Analytics'
        }
      },
      {
        path: '/userAnalytics',
        name: 'userAnalytics',
        component: () => import('@/views/admin/userAnalytics.vue'),
        meta: {
          title: 'User Analytics'
        }
      },
      {
        path: '/myProfile',
        name: 'profile',
        component: () => import('@/views/profile.vue'),
        meta: {
          title: 'My Profile'
        }
      },
      {
        path: '/liveCall',
        name: 'liveCall',
        component: () => import('@/views/liveCall.vue'),
        meta: {
          title: 'Live Call'
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true,
      title: 'Login'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    meta: {
      requiresGuest: true,
      title: 'Admin Login'
    }
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: () => import('../views/invitedUserRegistration'),
    meta: {
      requiresGuest: true,
      title: 'Invited User Registeration'
    }
  },
  {
    path: '/kioskRegistration/:id',
    name: 'kioskRegistration',
    component: () => import('../views/invitedKioskRegistration.vue'),
    meta: {
      requiresGuest: true,
      title: 'Kiosk Registeration'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next('/login');
  }
  else {
    next();
  }
})

router.afterEach((to, from) => {
  const DEFAULT_TITLE = 'Dashboard';
  console.log('Route Info: ', from.path)
  if(from.path === '/liveCall'){
    store.dispatch('endCall')
  }
  Vue.nextTick(() => {
    document.title = ` ${to.meta.title} | ${DEFAULT_TITLE} `;
  });
});

export default router
