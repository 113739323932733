<template>
  <v-row>
    <v-col cols="12" align="center" justify="center" class="mt-15">
      <div class="video-panel" id="video-panel"></div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "telepresence",
  computed: {
    ...mapGetters([
      "userProfile",
      "getMeetingUrl",
      "getWindowDimensions",
      "getOutgoingCall",
      "getCallInitiateListener",
    ]),
  },
  data() {
    return {
      api: null,
      callEndTimeout: 0,
      callInitiated: false,
      callRecieve: false,
      participants: null,
      componentKey: 0,
      videoHeight: window.innerHeight - 200,
      videoWidth: window.innerWidth - 500,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/liveCall") {
        this.forceRerender();
      }
      if (to.path === "/") {
        console.log('Are you being called')
        this.endCall();
      }
    },
    getWindowDimensions(newVal) {
      this.videoHeight = newVal.height - 250;
      this.videoWidth = newVal.width - 550;
      this.scaleJitsi(this.videoWidth, this.videoHeight);
    },
  },
  methods: {
    endCall() {
      this.$router.push("/");
      this.$store.dispatch("setUserOffCall");
      this.$store.commit("setMeetingCode", null);
      this.$store.commit("setIncomingCallCount", 0);
      if (this.getMeetingUrl.initiator === this.userProfile.data().name) {
        // Stop listing to that event
        this.getCallInitiateListener();
        this.$store.dispatch("endExistingCalls", this.getOutgoingCall);
      } else {
        console.log("Particiapnt at End Call", this.getMeetingUrl);
        //this.$store.dispatch("endExistingCalls", this.getOutgoingCall);
        this.$store.dispatch("stopListener", this.getMeetingUrl.meetingId);
      }
    },
    scaleJitsi(width, height) {
      document.getElementById(this.api.getIFrame().id).style.width =
        width + "px";
      document.getElementById(this.api.getIFrame().id).style.height =
        height + "px";
    },
  },
  mounted() {
    console.log("CREATE VIDEO BOX CALLED:", this.getMeetingUrl.meetingCode);
    if (this.getMeetingUrl.meetingCode === undefined) {
      this.$router.push("/");
    } else {
      this.$store.commit("setUserCallStatus", true);
      console.log("CREATE VIDEO BOX CALLED:", this.getMeetingUrl);
      const domain = "meet.arsconnect.com/" + this.getMeetingUrl.meetingCode;

      // const domain = "clara-connect1.arsconnect.com"
      const options = {
        roomName: this.getMeetingUrl.meetingCode,
        width: this.videoWidth,
        height: this.videoHeight,
        parentNode: document.querySelector("#video-panel"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          TOOLBAR_BUTTONS: ["hangup", "camera", "microphone"],
          TOOLBAR_ALWAYS_VISIBLE: false,
          HIDE_INVITE_MORE_HEADER: true,
          DISABLE_VIDEO_BACKGROUND: true,
          DEFAULT_BACKGROUND: "#FFF",
        },
        userInfo: {
          email: "",
          displayName: this.userProfile.data().name,
        },
      };
      // let iframes = document.getElementsByTagName("iframe");
      // iframes[0].contentWindow.console.info = () => {};
      window.JitsiMeetExternalAPI =
        window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
      this.api = new window.JitsiMeetExternalAPI(domain, options);
      console.log(
        "Logging the entire Iframe: ",
        document.getElementsByTagName("iframe")[0].contentWindow
      );
      this.api.addListener("participantLeft", () => {
        this.participants = this.api.getNumberOfParticipants();
        console.log(
          "Number of Participants when some one left",
          this.participants
        );
        // if (this.participants === 1) {
        //   console.log("Participant left");
        //   this.endCall()
        // }
      });

      this.api.addListener("participantJoined", () => {
        console.log("Participant Joined");
        this.participants = this.api.getNumberOfParticipants();
        console.log(
          "Number of Participants when some one joined",
          this.participants
        );
        // if (this.noOfParticipants >= 1) {
        //   clearTimeout(this.callEndTimeout);
        // }
      });

      this.api.addListener("readyToClose", () => {
        this.endCall();
      });
      console.log(this.api);
    }
  },
};
</script>
