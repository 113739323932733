import * as fb from '../../firebase/index.js'

export default {
  state: {
    userRoles: []
  },
  getters: {
    userRoles: state => state.userRoles,
  },
  mutations: {
    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },
    appendUserRole(state, userRole) {
      state.userRoles.push(userRole);
    }
  },
  actions: {
    getUserRoles({ commit, getters }) {
      let roles = [];
      return new Promise((resolve, reject) => {
        fb.userRolesCollection.get()
          .then(querySnapshot => {
            if (querySnapshot.empty) resolve(null);
            querySnapshot.forEach(role => {
              roles.push({ data: role.data(), id: role.id });
            });
            commit('setUserRoles', roles);
            resolve('Fetched all User Roles')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    addNewRole({ dispatch }, role) {
      return new Promise((resolve, reject) => {
        const newRole = {
          name: role.name,
          description: role.description,
          dateCreated: new Date(),
          lastUpdated: new Date(),
          userCount: 0
        };
        fb.userRolesCollection.add(newRole)
          .then(() => {
            dispatch('getUserRoles');
            resolve('New role added successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    updateRole({ dispatch }, role) {
      return new Promise((resolve, reject) => {
        fb.userRolesCollection.doc(role.id).update({
          name: role.name,
          description: role.description,
          lastUpdated: new Date(),
        })
          .then(() => {
            dispatch('getUserRoles');
            resolve('User role updated successfully.');
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    deleteRole({ dispatch }, roleId) {
      return new Promise((resolve, reject) => {
        fb.userRolesCollection.doc(roleId).delete()
          .then(() => {
            dispatch('getUserRoles');
            resolve('Role deleted successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    }
  }
}