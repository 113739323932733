
import * as fb from "../../firebase";
import router from "../../router";

export default {
  state: {
    allRegisteredKiosks: [],
    allInvitedKiosks: [],
    invitedKioskProfile: {},
    kioskAnalytics: {
      qnaAnalytics: [],
      userSessionAnalytics: [],
      totalUserSessions: 0,
      totalQuestionsAsked: 0,
      overallPerformance: 0,
    },
    assignedKiosks: []
  },
  getters: {
    allRegisteredKiosks: state => state.allRegisteredKiosks,
    allInvitedKiosks: state => state.allInvitedKiosks,
    invitedKioskProfile: state => state.invitedKioskProfile,
    kioskAnalytics: state => state.kioskAnalytics,
    assignedKiosks: state => state.assignedKiosks

  },
  mutations: {
    setAllRegisteredKiosks(state, kiosks) {
      state.allRegisteredKiosks = kiosks;
    },
    setAllInvitedKiosks(state, kiosks) {
      state.allInvitedKiosks = kiosks;
    },
    setInvitedKioskProfile(state, kiosk) {
      state.invitedKioskProfile = kiosk;
    },
    setKioskAnalytics(state, analyticsObj) {
      state.kioskAnalytics = analyticsObj;
    },
    setAssignedKiosks(state, kiosks) {
      state.assignedKiosks = kiosks
    }

  },
  actions: {
    inviteNewKiosk({ state }, kiosk) {
      return new Promise((resolve, reject) => {
        console.log(state.userId);
        fb.invitedKiosksCollection.add({
          name: kiosk.name,
          email: kiosk.email,
          description: kiosk.description,
          location: kiosk.location,
          fdn: kiosk.fdn,
          createdOn: new Date(),
        }).then(() => {
          resolve('Kiosk Invitation sent successfully.');
        })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    addNewKiosk({ dispatch }, kiosk) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(kiosk.email, kiosk.password)
          .then(cred => {
            fb.kioskCollection.doc(cred.user.uid).set({
              name: kiosk.name,
              email: kiosk.email,
              location: kiosk.location,
              description: kiosk.description,
              onlineStatus: 'Unavailable',
              createdOn: new Date(),
            }).then(() => {
              // Create Stripe account

              fb.invitedKiosksCollection.doc(kiosk.invitationId).delete()
                .then(() => {
                  //fb.auth.signOut();
                  resolve('Kiosk account activated successfully.');
                })

              dispatch('createStripeCustomer', 'Kiosk')
            })
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    getInvitedKioskInformation({ commit }, kId) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection.doc(kId).get()
          .then(kioskData => {
            commit('setInvitedKioskProfile', kioskData.data());
            resolve('Fetched Kiosk Information')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    getAllRegisteredKiosks({ commit, getters }, kiosks) {
      return new Promise((resolve, reject) => {
        let queryRef;
        if (getters.userProfile.data().type === 'dev') {
          queryRef = fb.kioskCollection
        }
        else if (getters.userProfile.data().userRole === 'Admin') {
          queryRef = fb.kioskCollection.where('location', '!=', 'Test')
        } else {
          if (kiosks.length > 1) {
            queryRef = fb.kioskCollection.where("name", "in", kiosks)
          }
        }
        queryRef.onSnapshot(snapshot => {
          if (snapshot.empty) console.log('Empty kiosk collection');
          commit('setAllRegisteredKiosks', snapshot.docs);
          resolve('Fetched all Registered Kiosks in collection');
        }, error => {
          reject(error.message);
        })

      })
    },
    // getAssignedKiosk({}){

    // },
    getAllInvitedKiosks({ commit }) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection.onSnapshot(snapshot => {
          if (snapshot.empty) console.log('Empty Kiosk collection');
          commit('setAllInvitedKiosks', snapshot.docs);
          resolve('Fetched all Invited Kiosks in collection');
        }, error => {
          reject(error.message);
        })

      })
    },
    getKioskAnalytics({ commit }, kioskId) {
      return new Promise((resolve, reject) => {
        let analyticsObj = {};
        fb.userSessionCollection.where('kioskID', '==', kioskId).get()
          .then(userSessionData => {
            if (userSessionData.empty) reject('No analytical data found');
            fb.qnaCollection.where('kioskID', '==', kioskId).get()
              .then(kioskData => {
                // if(kioskData.empty) reject('No analytical data found');
                analyticsObj = {
                  qnaAnalytics: kioskData,
                  userSessionAnalytics: userSessionData,
                  totalQuestionsAsked: kioskData.size,
                  totalUserSessions: userSessionData.size,
                  overallPerformance: 0
                }
                commit('setKioskAnalytics', analyticsObj);
                resolve('Kiosk analytical data fetched');
              })
              .catch(error => {
                reject(error.message);
              })
          })
          .catch(error => {
            reject(error.message)
          })
      })

    },
    updateRegisteredKioskInformation({ state }, kioskData) {
      return new Promise((resolve, reject) => {
        fb.kioskCollection.doc(kioskData.id).update({
          name: kioskData.name,
          location: kioskData.location,
          description: kioskData.description,
          email: kioskData.email
        })
          .then(() => {
            resolve('Kiosk Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    updateInvitedKioskInformation({ state }, kioskData) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection.doc(kioskData.id).update({
          name: kioskData.name,
          location: kioskData.location,
          description: kioskData.description,
          email: kioskData.email
        })
          .then(() => {
            resolve('Kiosk Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteRegisteredKiosk({ state }, kioskId) {
      return new Promise((resolve, reject) => {
        fb.kioskCollection.doc(kioskId).delete()
          .then(() => {
            resolve('Kiosk deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteInvitedKiosk({ state }, kioskId) {
      return new Promise((resolve, reject) => {
        fb.invitedKiosksCollection.doc(kioskId).delete()
          .then(() => {
            resolve('Kiosk deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    saveKioskConfig({ state }, kioskConfig) {
      return new Promise((resolve, reject) => {
        fb.kioskCollection.doc(kioskConfig.kId).update({
          cmsLink: kioskConfig.cmsLink,
          videoLink: kioskConfig.videoLink,
          host: kioskConfig.host,
          endpointKey: kioskConfig.endpointKey,
          route: kioskConfig.route,
          sitepalId: kioskConfig.sitepalId,
          avatarId: kioskConfig.avatarId,
          avatarName: kioskConfig.avatarName
        })
          .then(() => {
            resolve('Kiosk Configuration Saved Successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },

  }
}