import * as fb from "../../firebase";
//import router from "../../router";

export default {
  state: {
    allRegisteredUsers: [],
    allInvitedUsers: [],

  },
  getters: {
    allRegisteredUsers: state => state.allRegisteredUsers,
    allInvitedUsers: state => state.allInvitedUsers,
  },
  mutations: {
    setAllRegisteredUsers(state, users) {
      state.allRegisteredUsers = users;
    },

    setAllInvitedUsers(state, users) {
      state.allInvitedUsers = users;
    }
  },
  actions: {
    getAllRegisteredUsers({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (getters.allRegisteredUsers.length === 0) {
          let queryRef;
          if (getters.userProfile.data().type === 'dev') {
            queryRef = fb.userCollection
          } else if (getters.userProfile.data().userRole === "Admin" && getters.userProfile.data().type !== 'dev') {
            queryRef = fb.userCollection.where("name", "!=", "ARS")
          }
          else {
            queryRef = fb.userCollection.where("groups", "array-contains-any", getters.userProfile.data().groups)
          }
          queryRef.onSnapshot(snapshot => {
            if (snapshot.empty) console.log('Empty user collection');
            let filteredUsers = snapshot.docs.filter(doc => doc.data().userRole !== "Super Admin")
            commit('setAllRegisteredUsers', filteredUsers);
            resolve('Fetched all Invited Users in collection');
          }, error => {
            reject(error.message);
          })
        }
      })
    },
    getAllInvitedUsers({ commit }) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.onSnapshot(snapshot => {
          if (snapshot.empty) console.log('Empty user collection');
          commit('setAllInvitedUsers', snapshot.docs);
          resolve('Fetched all Invited Users in collection');
        }, error => {
          reject(error.message);
        })

      })
    },
    updateRegisteredUserInformation({ state }, userData) {
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(userData.id).update({
          name: userData.name,
          userRole: userData.userRole,
          email: userData.email
        })
          .then(() => {
            resolve('User Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteRegisteredUser({ state }, userId) {
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(userId).delete()
          .then(() => {
            resolve('User deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    updateInvitedUserInformation({ state }, userData) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(userData.id).update({
          name: userData.name,
          userRole: userData.userRole,
          email: userData.email,
          assignedKiosks: userData.assignedKiosks
        })
          .then(() => {
            resolve('User Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteInvitedUser({ state }, userId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(userId).delete()
          .then(() => {
            resolve('User deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },

    removeUserFromGroup({ getters }, user) {
      if (getters.userProfile.data().groups.includes(user.groupName)) {
        console.log('remove user from group called')
        return new Promise((resolve, reject) => {
          fb.userCollection.doc(user.userId).update({
            groups: fb.firebase.firestore.FieldValue.arrayRemove(
              user.groupName
            )
          }).then(() => {
            resolve('User updated')
          })
            .catch(error => {
              reject(error.message)
            })
        })
      }

    }

  }
}